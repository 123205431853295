<template>
    <bar-amount-by-metadata :labels="labels"
                            :datasets="datasets"
                            :right-padding="rightPadding"/>
</template>

<script>
import BarAmountByMetadata from "./shared/BarAmountByMetadata";
import {Vibrant} from '@/color_schemes/custom';

export default {
    components: {
        BarAmountByMetadata
    },
    props: {
        metadata: {
            type: Object,
            required: true
        },
        name: {
            type: String,
            required: true
        },
        top: {
            type: Number,
            default: 50
        },
        formatter: {
            type: Function,
            default: (k, v) => {
                return [k, v];
            }
        },
        datalabels: {
            type: Object,
            default: () => ({})
        },
        rightPadding: {
            type: Number,
            default: 50
        }
    },
    computed: {
        sorted() {
            const source = this.metadata[this.name];

            const sorted = Object.entries(source)
                // eslint-disable-next-line no-unused-vars
                .sort(([k, v], [pk, pv]) => {
                    return pv - v;
                })
                .slice(0, this.top)
                .map(([k, v]) => this.formatter(k, v));

            return Object.fromEntries(sorted);
        },
        labels() {
            return Object.keys(this.sorted);
        },
        values() {
            return Object.values(this.sorted);
        },
        datasets() {
            return [
                {
                    label: 'Doações em Kg',
                    data: this.values,
                    backgroundColor: Vibrant,
                    datalabels: this.datalabels
                }
            ];
        }
    }
}
</script>
