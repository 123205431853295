<template>
    <main class="app-theme">

        <navbar-app/>

        <template v-if="dateCampaign.isReady">

            <campaign-ended-app v-if="dateCampaign.ended"/>

            <template v-else>
                <notifications classes="notify-new-donation"
                               group="new-donation"
                               position="top left"
                               :width="notificationWidth"/>

                <div class="row">
                    <div class="column">
                        <banner-rotator/>
                    </div>
                    <div class="column">
                        <div class="cards-container">
                            <div class="card">
                                <div class="card-body center">
                                    <h5 class="card-title">Total Arrecadado</h5>
                                    <donations-kg-counter v-if="campaign.aggregate && campaign.aggregate.sum_amount"
                                                          :counter="campaign.aggregate.sum_amount"/>
                                    <p v-else-if="!campaign.init" class="text-center text-muted">
                                        <font-awesome-icon icon="circle-notch" spin size="3x"/>
                                    </p>
                                    <p v-else class="text-center"><em>Não há doações</em></p>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-body center">
                                    <h5 class="card-title">Últimas Doações</h5>
                                    <latest-donations v-if="campaign.aggregate && campaign.aggregate.latest"
                                                      :donations="campaign.aggregate.latest"
                                                      :metadata="'unidades'"
                                                      :value-formatter="formatCampusAmount"
                                                      :limit="5"/>
                                    <p v-else-if="!campaign.init" class="text-center text-muted">
                                        <font-awesome-icon icon="circle-notch" spin size="3x"/>
                                    </p>
                                    <p v-else class="text-center"><em>Não há doações</em></p>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-body center">
                                    <h5 class="card-title">Doações por Unidade (Kg)</h5>
                                    <bar-donation-amount-by-metadata v-if="campaign.aggregate && campaign.aggregate.metadata"
                                                                     :metadata="campaign.aggregate.metadata"
                                                                     name="unidades"
                                                                     :formatter="formatCampusResult"/>
                                    <p v-else-if="!campaign.init" class="text-center text-muted">
                                        <font-awesome-icon icon="circle-notch" spin size="3x"/>
                                    </p>
                                    <p v-else class="text-center"><em>Não há doações</em></p>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-body center">
                                    <h5 class="card-title">Doações Diárias (Kg)</h5>
                                    <bar-donation-amount-by-date v-if="campaign.aggregate && campaign.aggregate.daily_amount"
                                                                 :amounts="campaign.aggregate.daily_amount"
                                                                 :formatter="convertToKg"
                                                                 :scale-formatter="scaledFormatter"
                                                                 :limit="7"/>
                                    <p v-else-if="!campaign.init" class="text-center text-muted">
                                        <font-awesome-icon icon="circle-notch" spin size="3x"/>
                                    </p>
                                    <p v-else class="text-center"><em>Não há doações</em></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </template>
    </main>
</template>

<script>
import CampaignMixin from "../../mixins/CampaignMixin";
import NavbarApp from "../../components/navbars/NavbarApp";
import BarDonationAmountByDate from "../../components/charts/BarDonationAmountByDate";
import LatestDonations from "../../components/lists/LatestDonations";
import BarDonationAmountByMetadata from "../../components/charts/BarDonationAmountByMetadata";
import DonationsKgCounter from "../../components/counters/DonationsKgCounter";
import KilogramHelper from "../../helpers/KilogramHelper";
import BannerRotator from "../../components/banners/BannerRotator";
import Formatter from "../../helpers/Formatter";
import CampaignEndedApp from "../../components/warnings/EndedCampaignApp";

export default {
    mixins: [CampaignMixin],
    components: {
        CampaignEndedApp,
        BannerRotator,
        BarDonationAmountByMetadata,
        DonationsKgCounter,
        LatestDonations,
        BarDonationAmountByDate,
        NavbarApp
    },
    methods: {
        _hook_onNewDonationCreated(donation) {
            const amount = KilogramHelper.convertToKilogram(donation.amount, true);

            this.$notify({
                duration: 2000,
                group: 'new-donation',
                title: 'Doação Recebida',
                text: `${donation.donor.name} doou ${amount}`
            });
        },
        formatCampusAmount(float) {
            return KilogramHelper.convertToKilogram(float, true);
        },
        formatCampusResult(key, value) {
            return [
                key,
                KilogramHelper.convertToKilogram(value)
            ];
        },
        convertToKg(float) {
            return KilogramHelper.convertToKilogram(float);
        },
        scaledFormatter(value) {
            return Formatter.formatDecimal(value, 0);
        }
    },
    computed: {
        notificationWidth() {
            return (window.innerWidth < 400 ? 300 : 400) + 'px';
        }
    }
}
</script>

