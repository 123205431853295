import {store} from '@/stores/authStore';
import DataHelper from './DataHelper';

export default class KilogramHelper {
    static _defKgRate = 1;

    static _kgRate = null;

    static getSuffix() {
        return 'Kg'
    }

    static get kgRate() {
        if (!this._kgRate) {
            this._kgRate = store.getters._store_getAuthSettingsProp('kg_rate') || this._defKgRate;
        }

        return this._kgRate;
    }

    static convertToKilogram(float, addSuffix = false, precision = 0) {
        const value = DataHelper.roundNumber(float / this.kgRate, precision);
        return addSuffix
            ? value + ' ' + this.getSuffix()
            : value
    }
}
