<template>
    <div v-if="banners.length" class="rotate-backgrounds" v-rotate-backgrounds="banners" :key="forceUpdate">
        <div class="overlay-fade"></div>
    </div>
</template>

<style scoped>
    .rotate-backgrounds {
        background-size: cover;
        background-position: center center;
        margin-left: 1.5%;
        margin-right: 0;
        margin-bottom: 1.5%;
        width: 98.5%;
        height: 100%;
        border-radius: 5px;
        box-shadow: 0 .75rem 1.5rem rgba(18, 38, 63, .03);
        min-height: 400px;
    }

    .overlay-fade {
        width: 100%;
        height: 100%;
    }

    @media (max-width: 1300px) {
        .rotate-backgrounds {
            width: 97.5%;
        }
    }

    @media (max-width: 768px) {
        main .rotate-backgrounds {
            min-height: 245px;
        }
    }
</style>

<script>
    import CommonMixin from "../../mixins/CommonMixin";
    import '../../directives/rotateBackgrounds';
    import {mapGetters} from "vuex";

    export default {
        mixins: [CommonMixin],
        data() {
            return {
                forceUpdate: 0,
                banners: [
                    require("@/assets/img/backgrounds/banner-default.jpg")
                ]
            }
        },
        methods: {
            getBackgrounds() {
                const fileNames = this._store_getAuthSettingsProp('banners') || [];

                const fullPathPromises = fileNames.map((banner, i, arr) => {
                    return this._common_getUserAsset(banner)
                        .getDownloadURL()
                        .then(url => arr[i] = url);
                });

                Promise.all(fullPathPromises).then((firebaseUrls) => {
                    const preloadPromises = firebaseUrls.map((url) => {
                        new Promise(resolve => {
                            let img = new Image();
                            img.onload = () => resolve(true);
                            img.src = url;
                        });
                    });

                    Promise.all(preloadPromises).then(() => {
                        this.banners = firebaseUrls;
                        this.forceUpdate++;
                    });
                });
            },
        },
        computed: {
            ...mapGetters([
                '_store_getAuthSettingsProp'
            ])
        },
        created() {
            this.getBackgrounds();
        }
    }
</script>


