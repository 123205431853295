import Vue from "vue";

Vue.directive('rotateBackgrounds', {
    // When the bound element is inserted into the DOM...
    inserted: function (el, binding) {

        let backgrounds = binding.value;
        let rotateInterval = binding.modifiers.rotateInterval || 8;
        let fadeDuration = binding.modifiers.rotateInterval || 1;

        if (!Array.isArray(backgrounds) || !backgrounds.length) {
            throw new Error('Value must be a valid array');
        }

        let fade = function(dark) {
            let overlay = document.querySelector('.overlay-fade');
            if (!overlay) return;
            overlay.style.transition = `background ${fadeDuration}s ease-in-out`;
            overlay.style.background = dark ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0)';
        };

        let rotate = function (pos = 0) {
            if (pos >= backgrounds.length) pos = 0;

            el.style.backgroundImage = "url(" + backgrounds[pos] + ")";

            if (backgrounds.length === 1) return;

            fade(false);

            window.setTimeout(() => {
                fade(true);
                window.setTimeout(() => rotate(++pos), fadeDuration * 1000);
            }, rotateInterval * 1000);

        };

        rotate();
    }
});
