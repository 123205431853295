<template>
    <hz-bar-reactive :chartData="chartdata"
                     :options="options"
                     :key="borderColor"/>
</template>

<script>
import HzBarReactive from "./reactive/HzBarReactive";
import 'chartjs-plugin-colorschemes/src/plugins/plugin.colorschemes';
import { Vibrant } from '@/color_schemes/custom';
import Formatter from '@/helpers/Formatter';

// eslint-disable-next-line no-unused-vars
import ChartDataLabels from 'chartjs-plugin-datalabels';
import DarkModeMixin from "@/components/charts/shared/DarkModeMixin";

export default {
    mixins: [DarkModeMixin],
    components: {
        HzBarReactive
    },
    props: {
        labels: {
            type: Array,
            required: true
        },
        datasets: {
            type: Array,
            required: true
        },
        rightPadding: {
            type: Number,
            default: 30
        }
    },
    computed: {
        chartdata() {
            return {
                labels: this.labels,
                datasets: this.datasets
            }
        },
        options() {
            return {
                responsive: true,
                maintainAspectRatio: false,
                layout: {
                    padding: {
                        right: this.rightPadding
                    }
                },
                plugins: {
                    colorschemes: {
                        scheme: Vibrant
                    },
                    datalabels: {
                        align: 'right',
                        anchor: 'end',
                        color: function(context) {
                            return context.dataset.backgroundColor;
                        },
                        font: {
                            weight: 'bold'
                        },
                        formatter: (value) => {
                            return Formatter.formatDecimal(value, 0);
                        }
                    }
                },
                legend: {
                    display: false,
                    labels: {
                        fontColor: '#ccc',
                        usePointStyle: true
                    }
                },
                tooltips: false,
                scales: {
                    xAxes: [{
                        gridLines: {
                            color: 'transparent',
                            zeroLineColor: 'transparent'
                        },
                        ticks: {
                            min: 0,
                            fontColor: this.borderColor
                        }
                    }],
                    yAxes: [{
                        gridLines: {
                            color: 'transparent',
                            zeroLineColor: 'transparent'
                        },
                        ticks: {
                            min: 0,
                            beginAtZero: true,
                            fontColor: this.borderColor
                        }
                    }]
                }
            }
        }
    }
}
</script>
