<template>
    <bar-amount-by-date :labels="labels"
                        :datasets="datasets"
                        :scale-y-formatter="scaleFormatter"
                        :value-formatter="scaleFormatter"/>
</template>

<script>
import moment from 'moment';
import BarAmountByDate from "./shared/BarAmountByDate";
import {Vibrant} from "@/color_schemes/custom";

export default {
    components: {
        BarAmountByDate
    },
    props: {
        amounts: {
            type: Object,
            required: true
        },
        limit: {
            type: Number,
            default: 7
        },
        formatter: {
            type: Function,
            default: (v) => { return v; }
        },
        scaleFormatter: {
            type: Function,
            default: (v) => { return v; }
        }
    },
    computed: {
        latest() {
            return Object.values(this.amounts)
                .splice(-this.limit)
                .map(x => this.formatter(x));
        },
        labels() {
            return Object.keys(this.amounts)
                .splice(-this.limit)
                .map(x => moment(x, "YYYYMMDD").format('DD/MM'));
        },
        datasets() {
            return [
                {
                    label: 'Doações em Kg',
                    data: this.latest,
                    backgroundColor: Vibrant[0]
                }
            ];
        }
    }
}
</script>
